import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const Step3InterestsFunding = ({ values, handleChange, errors, touched }) => {
  return (
    <>
      <div className="mb-4 md:!mb-6">
        <label
          htmlFor="interests"
          className="text-lg font-semibold text-black mb-2"
        >
          Interests
        </label>
        <br />
        <select
          className="select select border border-gray-300 rounded-md px-2 py-2 w-full focus:outline-none focus:border-blue-500"
          name="interests"
          value={values.interests}
          onChange={handleChange}
        >
          <option defaultChecked value="">
            Select Interest
          </option>
          <option value="Government">Government</option>
          <option value="Discovery">Discovery</option>
          <option value="Manufacturing">Manufacturing</option>
          <option value="Marketplace">Marketplace</option>
          <option value="Mobile">Mobile</option>
          <option value="Platform">Platform</option>
          <option value="Consulting">Consulting</option>
          <option value="Engineering">Engineering</option>
          <option value="Others">Others</option>
          <option value="Rental">Rental</option>
          <option value="Research">Research</option>
        </select>
      </div>

      <FormControl className="form-outline">
        <label htmlFor="type" className="text-lg font-semibold text-black">
          Funded or Bootstrapped
        </label>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="type"
          id="type"
          value={values.type}
          onChange={handleChange}
        >
          <div className="form-outline">
            <FormControlLabel
              value="Funded"
              control={<Radio />}
              label="Funded"
            />
            <FormControlLabel
              value="Bootstrapped"
              control={<Radio />}
              label="Bootstrapped"
            />
          </div>
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default Step3InterestsFunding;
