import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import { MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import Header from "./Header";

const Signup = () => {
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    linkedInUrl: Yup.string().required("Linkedin URL is required"),
  });

  /////////////////////////////////////////

  const navigate = useNavigate();
  const [selRole, setSelRole] = useState("startup");

  const userSubmit = async (formdata, { setSubmitting }) => {
    formdata.role = selRole ?? "startup";
    setSubmitting(true);
    const res = await fetch(`${app_config.apiurl}/users/add`, {
      method: "POST",
      body: JSON.stringify(formdata),
      headers: { "Content-Type": "application/json" },
    });

    setSubmitting(false);

    //pop up
    if (res.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "You have registered successfully",
      }).then(() => {
        navigate("/main/login"); // Navigate to login page after clicking "OK"
      });
      if (selRole === "startup") {
        const data = await res.json();
        console.log(data);
        Swal.fire({
          icon: "success",
          title: "Account Created!",
          text: "Please check your email to verify and activate your account.",
        });
      } else if (selRole === "investor") {
        const data = await res.json();
        console.log(data);
      }
    } else {
      let response = await res.json();
      let errorMessage =
        typeof response === "object" && response !== null && "error" in response
          ? response.error
          : "Please Enter right credentials";
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage, //"Please Enter right credentials",
      });
    }
  };

  return (
    <div className="bg-white">
      <Header />
      <div className="flex justify-center mt-20 md:mt-0 md:items-center h-[88vh]">
        <div className="">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            {/* Left Section: Form */}
            <div className="flex flex-col justify-center items-center">
              <p className="text-center text-xl font-semibold text-black mb-4">
                Join as Startup
              </p>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  password: "",
                  linkedInUrl: "",
                }}
                validationSchema={SignupSchema} // Add the validation schema here
                onSubmit={userSubmit}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Name Input */}
                    <div className="md:w-80 w-64">
                      <MDBInput
                        label="Name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        name="name"
                      />
                      {errors.name && touched.name ? (
                        <div className="text-deny">{errors.name}</div>
                      ) : null}
                    </div>

                    {/* Email input */}
                    <div>
                      <MDBInput
                        label="Email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        name="email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-deny">{errors.email}</div>
                      ) : null}
                    </div>

                    {/* Password input */}
                    <div>
                      <MDBInput
                        label="Password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        name="password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-deny">{errors.password}</div>
                      ) : null}
                    </div>
                    {/* <div>
                      <FormControl className="ps-3 pb-4">
                        <FormLabel id="demo-radio-buttons-group-label">
                          Role
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="startup"
                          onChange={(e, v) => setSelRole(v)}
                          value={selRole}
                        >
                          <div className="">
                            <FormControlLabel
                              value="startup"
                              control={<Radio />}
                              label="Startup"
                            />
                            <FormControlLabel
                              value="investor"
                              control={<Radio />}
                              label="Investor"
                            />
                            <FormControlLabel
                              value="user"
                              control={<Radio />}
                              label="Common User"
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div> */}

                    {/* LinkedIn URL Input */}
                    <div>
                      <MDBInput
                        label="LinkedIn Profile"
                        type="url"
                        value={values.linkedInUrl}
                        onChange={handleChange}
                        name="linkedInUrl"
                      />
                      {errors.linkedInUrl && touched.linkedInUrl ? (
                        <div className="text-deny">{errors.linkedInUrl}</div>
                      ) : null}
                    </div>

                    {/* Submit Button */}
                    <div>
                      <button
                        className="w-full py-2 bg-skincolor font-medium text-sm text-black rounded-md "
                        type="submit"
                      >
                        REGISTER
                      </button>
                    </div>

                    {/* Login Link */}
                    <div className="text-center">
                      <a
                        href="/main/login"
                        className="text-blue-500 hover:underline"
                      >
                        Already have an account? Login
                      </a>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

            {/* Right Section: Image */}
            <div className="hidden md:flex">
              <img
                src="/login-grouppeople.jpg"
                alt="Signup illustration"
                className="w-full h-[88vh]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
