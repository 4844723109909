import React from "react";
import { MDBTextArea } from "mdb-react-ui-kit";
import { startupStages } from "../../Constants";

const Step2BriefStage = ({
  values,
  handleChange,
  errors,
  touched,
  handleStageSelection,
  setFieldValue,
}) => {
  return (
    <>
      <div className="form-outline mt-24 md:mt-4">
        <MDBTextArea
          label="Brief"
          type="text"
          id="brief"
          rows={2}
          value={values.brief}
          onChange={handleChange}
          name="brief"
        />
        {errors.brief && touched.brief ? (
          <div className="text-deny">{errors.brief}</div>
        ) : null}
        <div>
          Word Count: {values.brief ? values.brief.split(/\s+/).length : 0} /
          150
        </div>
      </div>

      <label
        className="text-lg font-semibold text-black mt-8"
        id="startupStages"
      >
        Startup stage
      </label>
      <div className="d-flex flex-column mt-2 btn-group shadow-0" role="group">
        {startupStages.map((stage) => (
          <div key={stage.value} className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="startupStages"
              value={stage.value}
              id={`stage-${stage.value}`}
              checked={values.startupStages.includes(stage.value)}
              onChange={() =>
                handleStageSelection(
                  stage.value,
                  setFieldValue,
                  values.startupStages,
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor={`stage-${stage.value}`}
            >
              {stage.text}
            </label>
          </div>
        ))}
      </div>

      {touched.startupStages && errors.startupStages && (
        <div className="text-deny">{errors.startupStages}</div>
      )}
    </>
  );
};

export default Step2BriefStage;
