import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AlphabetAvatar from "../AlphabetAvatar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { checkProfileImageExist } from "../../util.js";
import app_config from "../../config";

const MyNetwork = ({ onRequestCountUpdate }) => {
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const token = sessionStorage.getItem("token");

  const fetchApprovedRequests = async () => {
    try {
      const response = await fetch(`${app_config.apiurl}/users/connections`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setApprovedRequests(data.result);
      setLoading(false);
      // Pass the count back to MyProfile component
      onRequestCountUpdate(data.result.length);
    } catch (error) {
      console.error("Error fetching approved requests:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApprovedRequests();
  }, []);

  if (loading) {
    return <p className="text-center mt-8 md:ml-32 mb-4">Loading network...</p>;
  }

  return (
    <div className="py-2 px-2 mb-4 md:-mt-8 -ml-4 md:ml-0 md:!py-10">
      {approvedRequests.length === 0 ? (
        <p className="text-center mt-8 md:ml-32 mb-4">
          Yet to create your network.
        </p>
      ) : (
        approvedRequests.map((request) => (
          <div
            key={request._id}
            className="flex flex-col !ml-9 md:-ml-8 md:w-[40rem] gap-2 py-4 px-2 md:px-0 sm:p-6 bg-white mx-2 md:mx-auto rounded-xl shadow-md mb-3 md:mb-5"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex items-center mr-4">
                  {checkProfileImageExist(request.investoravatar) ? (
                    <img
                      className="h-16 w-16 md:h-16 md:w-16 rounded-full"
                      src={request.investoravatar}
                      alt="avatar"
                    />
                  ) : (
                    <AlphabetAvatar
                      alphabet={request.firstName.charAt(0)}
                      className="!h-16 !w-16 md:h-16 md:w-16"
                    />
                  )}
                </div>

                {/* Name Section */}
                <h2 className="text-base md:text-2xl font-medium capitalize">
                  {request.firstName} {request.lastName}
                </h2>
              </div>

              {/* Arrow icon placed at the right for mobile */}
              <div className="md:hidden flex-shrink-0 mr-3">
                <Link
                  className="bg-white border-2 border-black p-2 rounded-full flex items-center justify-center h-7 w-7"
                  to={"/startup/investordetails/" + request._id}
                >
                  <ArrowForwardIcon />
                </Link>
              </div>

              <div className="hidden md:block mr-3">
                <Link
                  className="bg-white border-2 border-black text-black p-2 rounded"
                  to={"/startup/investordetails/" + request._id}
                >
                  View Profile
                </Link>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default MyNetwork;
